import React from 'react'
import { styled } from 'styled-components'
import robo from '../media/logo4.png'

const Footer = () => {
    const ContainerBox = styled.div`
        position: relative;
        width: 100vw;
        height: 80vh;
        background: #EDEDED;
        display: grid;
        grid-template-rows: 1fr 50px;
        @media screen and (max-width: 1400px) {
            height: 60vh;
        }
        @media screen and (max-width: 700px) {
            height: 100%;
        }
    `;    
    const ContentBox = styled.div`
        display: grid;
        grid-template-columns: auto auto 1fr;
        padding: 100px 100px 50px;
        @media screen and (max-width: 1400px) {
            padding: 80px;
        }
        @media screen and (max-width: 1024px) {
            padding: 40px;
        }
        @media screen and (max-width: 700px) {
            grid-template-columns: 1fr;
        }
    `;
    
    const Otherpart = styled.div`
        padding: 0 50px;
        h3 {
            font-family: "Roboto Condensed", sans-serif;
            color: #252525;
            font-size: 17px;
            margin-bottom: 24px;
        }
        p {        
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            color: #252525;
            margin-bottom: 13px;
            text-transform: capitalize;
            letter-spacing:1px;
            font-weight: 400;
        }
        @media screen and (max-width: 1424px) {
            padding: 0 50px 0 0;
        }
        @media screen and (max-width: 700px) {
            padding: 0 0 30px 0;
        }
    `;
    const CopyBox = styled.div`
        border-top: 1px solid #545454;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
        p {
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            color: #000;

            stonge {
            color: #000;
            font-size: 13px;
            font-weight: bold;
            }
        @media screen and (max-width: 600px) {
            font-size: 9px;
        }
        }
        @media screen and (max-width: 600px) {
            padding: 0 15px;
        }
    `;
    const Otherleftpart = styled.div`
        /* padding: 0 50px; */
        display: flex;
        justify-content: flex-end;
        align-items:flex-end;
        flex-direction: column;
        h3 {
            font-family: "Roboto Condensed", sans-serif;
            color: #252525;
            font-size: 17px;
            margin-bottom: 24px;
        }
        p {        
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            color: #252525;
            margin-bottom: 13px;
            text-transform: capitalize;
            letter-spacing:1px;
            font-weight: 400;
        }
        @media screen and (max-width: 700px) {
            padding: 0 0 30px 0;
            justify-content: flex-start;
            align-items:flex-start;
        }
    `;
    const AddressContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 700px) {
            padding: 0 0 30px 0;
            justify-content: flex-start;
            align-items:flex-start;
        }
    `;
    const AddressText = styled.div`
    `;
    const AddressLogo = styled.div`
    
    `;
    const ImageContainer = styled.div`
        position: relative;
        width: 165px;
        height: 100px;
    `;
    const ImageBox = styled.img`
        position: absolute;
        width: 100%;
        height: 100%;
    `;
  return (
    <ContainerBox>
        <ContentBox>            
            <Otherpart>
                <h3>Why RSB</h3>
                <p>SAP Resource Services</p>
                <p>IT Technical Support Services</p>
                <p>Mechanical Engineering Onsite CAD/Design/CAE services</p>
                <p>IT Staffing & Consulting</p>
                <p>Network & Data Security Services</p>
                <p>Back office Services</p>
            </Otherpart>      
            <Otherpart>
                <h3>Follow Us</h3>
                <p>LinkedIn</p>
                <p>Twitter</p>
                <p>Facebook</p>
                <p>Instagram</p>
            </Otherpart>
            <AddressContainer>
                <AddressText>    
                    <Otherleftpart>
                        <h3>Get in touch</h3>
                        <p>RSB Techno Services</p>
                        <p>Suave Spaces</p>
                        <p>601,602 Suratwala Mark Plazzo, Opp. KFC,</p>
                        <p>Hinjawadi, Pune,</p>
                        <p>Maharashtra 411057</p>
                    </Otherleftpart>
                 </AddressText>
                <AddressLogo>                    
                    <ImageContainer>
                        <ImageBox src={robo} />
                    </ImageContainer>
                </AddressLogo>
            </AddressContainer>            
        </ContentBox>
        <CopyBox>            
            <p>© RSB Techno Services. All Rights Reserved.</p>
            <p>Created By <stonge>iFutureTech</stonge>.</p>
        </CopyBox>
    </ContainerBox>
  )
}

export default Footer