import { createGlobalStyle ,css} from "styled-components";

export const fontFaces = css`
    @import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap')
`;

const GlobleStyle = createGlobalStyle`
    ${fontFaces};
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* font-family: 'Montserrat', sans-serif; */
        a{            
            text-decoration:none;
        }
    }

    html, body{
        overflow-x: hidden;
        scroll-behavior: smooth;
        background: #FFFFFF;
    }
    body::-webkit-scrollbar {
        width: 5px;
        background: none;
        display: none;
    }
    
    body::-webkit-scrollbar-track {
        
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: #D4AF37;
        outline: 1px solid slategrey;
    }
`;

export default GlobleStyle;