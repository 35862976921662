import React from 'react'
import { styled } from 'styled-components';

const Careercontent = ({title, desc, imagefile}) => {
    const ContainerBox = styled.div`
        position: relative;
        width: 100vw;
        height: 100vh;
        padding: 0px 100px;
        display: grid;
        @media screen and (max-width: 1500px) {
            padding: 0px 80px;
        }
        @media screen and (max-width: 1025px) {
            padding: 0px 60px;
            height: 100%;
        }
        @media screen and (max-width: 600px) {
            padding: 0px 20px;
        }
    `;
    const DarkBox = styled.div`
        position: relative;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 2fr 3fr;
        @media screen and (max-width: 1325px) {
            grid-template-columns: 2fr 2fr;
        }
        @media screen and (max-width: 1025px) {
            grid-template-columns: 1fr;
        }

    `;
    const DarkTextBox = styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    `;
    const DarkImgBox = styled.div`
        position: relative;
        @media screen and (max-width: 1025px) {
            width: 500px;
            height: 500px;
        }
        @media screen and (max-width: 600px) {
            width: 300px;
            height: 300px;
        }
        @media screen and (max-width: 350px) {
            width: 200px;
            height: 200px;
        }
    `;

    const GridTextData = styled.h3`
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 45px;
        font-weight: 600;
        background: #0E7DE6;
        background: linear-gradient(to right, #0E7DE6 0%, #0A57A1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width: 600px) {
        font-size: 25px;
        }
    `;
    const ImgBox = styled.img`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `;
    const TextSmallbox = styled.p`
        margin-top: 15px;
        color: #687076;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    `;
    
  return (
    <ContainerBox>
        <DarkBox>
            <DarkTextBox>
                <GridTextData>{title}</GridTextData>
                <TextSmallbox>{desc}</TextSmallbox>
            </DarkTextBox>
            <DarkImgBox>
                <ImgBox src={imagefile} />
            </DarkImgBox>
        </DarkBox>
    </ContainerBox>
  )
}

export default Careercontent