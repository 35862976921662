import { Route, Routes } from "react-router-dom";
import GlobleStyle from "./globelStyle"
import React from "react";
import Loadingpage from "./Loadingpage";
import Service from "./pages/Service";
import Career from "./pages/Career";
import Contact from "./pages/Contact";

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
// const Career = React.lazy(() => import('./pages/Career'));
// const Contact = React.lazy(() => import('./pages/Contact'));

function App() {
  return (
  <>
  <GlobleStyle />
  <Routes>
    <Route path="/" element = {
      <React.Suspense fallback = {<Loadingpage />} >
        <Home />
      </React.Suspense>
    } />
    <Route path="/service" element = {
      <React.Suspense fallback = {<Loadingpage />} >
        <Service />
      </React.Suspense>
    } />
    <Route path="/about" element = {
      <React.Suspense fallback = {<Loadingpage />} >
        <About />
      </React.Suspense>
    } />
    <Route path="/career" element = {
      <React.Suspense fallback = {<Loadingpage />} >
        <Career />
      </React.Suspense>
    } />
    <Route path="/contact" element = {
      <React.Suspense fallback = {<Loadingpage />} >
        <Contact />
      </React.Suspense>
    } />
    {/* <Route path="/contact" element = {
      <React.Suspense fallback = {<Loadingpage />} >
        <Contact />
      </React.Suspense>
    } /> */}
    <Route path="*" element = {
      <React.Suspense fallback = {<Loadingpage />} >
      <Home />
    </React.Suspense>
    } />
  </Routes>
  </>
  );
}

export default App;