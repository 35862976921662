import React from 'react'
import { styled } from 'styled-components'

const Contacttext = () => {
    const ContainerBox = styled.div`
        margin-top: 80px;
        width: 100vw;
        padding: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        @media screen and (max-width: 1200px) {
            padding: 60px;
        }
        @media screen and (max-width: 600px) {
            padding: 20px;
        }
        p{
            color: #545454;
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 19px;
        }
        h3 {            
            color: #252525;
            font-family: "Lato", sans-serif;
            font-size: 30px;
            font-weight: 600;
            @media screen and (max-width: 600px) {
                font-size: 18px;
            }
        }
    `;
  return (
    <ContainerBox>
        <p>Contact Us</p>
        <h3>We'd love to hearfrom you!</h3>
        <h3>Please contact us to discuss new business or any other queries.</h3>
    </ContainerBox>
  )
}

export default Contacttext