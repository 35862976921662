import React from 'react'
import { styled } from 'styled-components';

const Contactmap = () => {
    const ContainerBox = styled.div`
        position: relative;
        width: 100vw;
        /* height: 100vh; */
        padding: 0 100px 100px 100px;
        @media screen and (max-width: 1200px) {
        padding: 0 60px 60px 60px;
        }
        @media screen and (max-width: 600px) {
        padding: 0 20px 20px 20px;
        }
    `;
    const DarkBox = styled.div`
        position: relative;
        background: #000;
        padding: 70px;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 3fr 2fr;
        background: rgb(20,79,237);
        background: linear-gradient(157deg, rgba(0,0,0,1) 59%, rgba(15,15,15,1) 100%);
        border-radius: 15px;
        -webkit-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        -moz-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        display: grid;
        grid-template-columns: auto 1fr;
        @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        padding: 30px;
        }
        @media screen and (max-width: 600px) {
        padding: 20px;
        }

    `;
    const Otherpart = styled.div`
        padding-right:50px;
        h3 {
            font-family: "Roboto Condensed", sans-serif;
            color: #B6BBC4;
            font-size: 18px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        p {        
            font-family: "Roboto Condensed", sans-serif;
            font-size: 16px;
            color: #FFF;
            margin-bottom: 13px;
            /* text-transform: capitalize; */
            letter-spacing:1px;
            font-weight: 400;
        }
    `;
    const Mapbox = styled.div`
        position: relative;
            height: 100%;
        @media screen and (max-width: 1200px) {
            width: 100%;
            height: 300px;
        }
    `;
    const Mapdata = styled.iframe`
        position: absolute;
        width: 100%;
        height: 100%;
    `;
  return (
    <ContainerBox>
        <DarkBox>
            <Otherpart>
                <h3>Address :</h3>
                <p>RSB Techno Services</p>
                <p>Suave Spaces</p>
                <p>601,602 Suratwala Mark Plazzo, Opp. KFC,</p>
                <p>Hinjawadi, Pune,</p>
                <p>Maharashtra 411057</p>
                <h3>Mail : </h3>
                <p>info@rsbtechnoservices.com</p>
                <p>contact@rsbtechnoservices.com</p>
                <p>hr@rsbtechnoservices.com</p>
                <h3>Contact No : </h3>
                <p>+ 91 911 221 78 78</p>
            </Otherpart>
            <Mapbox>
                <Mapdata src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d259.61174185087594!2d73.74782900515714!3d18.59041138854353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b960040a8f1d%3A0x442ddcbba499eb66!2sSuratwala%20Mark%20Plaza%2C%20Hinjawadi%20Village%2C%20Hinjawadi%2C%20Pimpri-Chinchwad%2C%20Maharashtra%20411057!5e0!3m2!1sen!2sin!4v1706674390322!5m2!1sen!2sin" />
            </Mapbox>
        </DarkBox>
    </ContainerBox>
  )
}

export default Contactmap