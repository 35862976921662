import React from 'react'
import { styled } from 'styled-components';
import bgdash from '../../../media/cdash.jpg'

const Careerdash = () => {
    const ContainerBox = styled.div`
        margin-top: 80px;
        position: relative;
        width: 100vw;
        height: calc(100vh - 80px);
        padding: 0 70px 40px;
        @media screen and (max-width: 900px) {
            padding: 0 40px 40px;
        }
        @media screen and (max-width: 900px) {
            padding: 0 20px 20px;
        }
    `;
    const MainConatiner = styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        background: #000;
        border-radius: 10px;
    `;
    const ImageBox = styled.img`
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        border-radius: 10px;
    `;

    const Displaybox = styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(229,225,218,0.9) 50%, rgba(255,26,109,0) 100%);  
        padding: 100px; 
        display: flex;
        justify-content: center;
        align-items: flex-start;  
        flex-direction: column;
        border-radius: 10px;

        h1 {
            font-family: "Roboto Condensed", sans-serif;
            font-size: 50px;
            font-weight: 500;
        }
        h3 {            
            font-family: "Lato", sans-serif;
            font-size: 30px;
            font-weight: 500;
        }
        @media screen and (max-width: 900px) {
            padding: 50px;
            align-items: center; 
            h1 {
                font-family: "Roboto Condensed", sans-serif;
                font-size: 35px;
                font-weight: 500;
                text-align: center; 
            }
            h3 {            
                font-family: "Lato", sans-serif;
                font-size: 18px;
                font-weight: 500;
                text-align: center; 
            }
        }
    `;
  return (
    <ContainerBox>
        <MainConatiner>
            <ImageBox src={bgdash} />
            <Displaybox>
                <h1>Build your future</h1>
                <h3>Explore exciting career opportunities</h3>
                <h3>with RSB Techno Services</h3>
            </Displaybox>
        </MainConatiner>
    </ContainerBox>
  )
}

export default Careerdash