import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const Homegridbox = ({title,img,desc}) => {
    const GridBox = styled.div`
        position: relative;
        height: 430px;
        background: rgb(20,79,237);
        background: linear-gradient(157deg, rgba(11,96,176,1) 59%, rgba(110,170,227,1) 100%);
        border-radius: 15px;
        -webkit-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        -moz-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        padding: 35px;
        display: grid;
        grid-template-rows: auto 230px;
    `;
    const GridTextData = styled.h3`
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 30px;
        font-weight: 600;
        @media screen and (max-width: 1350px) {
            font-size: 28px;
        }
    `;
    const GridImgBox = styled.div`
        position: relative;
    `;
    const ImgBox = styled.img`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `;
//   return (
//     <GridBox>
//         <GridTextData>{title}</GridTextData>
//         <GridImgBox>
//             <ImgBox src={img} />
//         </GridImgBox>
//     </GridBox>
//   )

    const ContainerBox = styled.div`
        width: 100%;
        height: 430px;

        &:hover > .card {
            cursor: pointer;
            transform: rotateY(180deg);
        }
    `; 

    const Card = styled.div`
        width: 100%;
        height: 100%;
        position: relative;
        transition: transform 1500ms;
        transform-style: preserve-3d;

    `;
    const Front = styled.div`
        width: 100%;
        height: 100%;
        position: absolute;
        backface-visibility: hidden;
        background: rgb(20,79,237);
        background: linear-gradient(157deg, rgba(11,96,176,1) 59%, rgba(110,170,227,1) 100%);
        border-radius: 15px;
        -webkit-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        -moz-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        padding: 35px;
        display: grid;
        grid-template-rows: auto 230px;
    `;
    const Back = styled.div`
        width: 100%;
        height: 100%;
        position: absolute;
        transform: rotateY(180deg);
        backface-visibility: hidden;
        background: #000;
        border-radius: 15px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `;
    const TextSmallbox = styled.p`
        color: #ededed;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        @media screen and (max-width: 1350px) {
            font-size: 14px;
        }
    `;

    const Backbutton = styled.div`
        width: 160px;
        height: 45px;
        background-color: #0B60B0;
        margin-top: 25px;
        border-radius: 30px;
        display: grid;
        place-content: center;
        background: #00d2ff; 
        background: -webkit-linear-gradient(to right, #3a7bd5, #00d2ff);  
        background: linear-gradient(to right, #3a7bd5, #00d2ff); 
        color: #fff;
        font-family: "Jost", sans-serif;
        font-size: 18px;
        font-weight: 500;


    `;
  return (
    <ContainerBox>
        <Card className='card'>
            <Front>
                <GridTextData>{title}</GridTextData>
                <GridImgBox>
                    <ImgBox src={img} />
                </GridImgBox>
            </Front>
            <Back>
                <GridTextData>{title}</GridTextData>
                <TextSmallbox>{desc}</TextSmallbox>
                <Link to="/service">
                    <Backbutton>Learn more</Backbutton>
                </Link>
            </Back>
        </Card>
    </ContainerBox>
  )
}

export default Homegridbox