import React, { useEffect } from 'react'
import Navbar from '../comp/Navbar'
import Footer from '../comp/Footer'
import Contacttext from './pageex/contact/Contacttext'
import Contactmap from './pageex/contact/Contactmap'

const Contact = () => {
  useEffect(() => {
      window.scroll(0,0);
  },[]);
  return (
    <>
      <Navbar nochanges={"#fff"} countfile={1}/>
      <Contacttext />
      <Contactmap />
      <Footer /> 
    </>
  )
}

export default Contact