import React from 'react'
import { styled } from 'styled-components'
import { Link } from 'react-router-dom';

const Coverpage = ({videofile, text1,text2,text3,text4}) => {
    const ContainerBox = styled.div`
    position: relative;
        /* margin-top: 60px; */
        width: 100vw;
        height: 100vh;
    `;
    const VideoContainer = styled.video`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

    `;
    const TextContainer = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,0.7) 50%, rgba(255,26,109,0) 100%);  
        display: flex;
        justify-content: center;
        align-items: flex-start;  
        flex-direction: column;
        padding-left: 150px;
        @media screen and (max-width: 500px) {
            padding-left: 0px;
            align-items: center;  
        }
        
    `;
    const TextData = styled.h1`
        color: white;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 50px;
        font-weight: 300;
        @media screen and (max-width: 1350px) {
            font-size: 35px;
        }
        @media screen and (max-width: 500px) {
            font-size: 20px;
        }
    `;
    const TextSmallData = styled.h1`
        padding-top: 10px;
        color: #ededed;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 18px;
        font-weight: 300;
        @media screen and (max-width: 500px) {
            width: 250px;
            align-items: center; 
        }
    `;

    const ButtonData = styled.div`
        width: 200px;
        height: 60px;
        background-color: #0B60B0;
        margin-top: 25px;
        border-radius: 30px;
        display: grid;
        place-content: center;
        @media screen and (max-width: 500px) {
            width: 150px;
            height: 45px;
        }
    `;

    const ButtonText = styled.h4`
        font-family: "Lato", sans-serif;
        font-weight: 300;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 300;
        @media screen and (max-width: 500px) {
            font-size: 14px;
        }
    `;
  return (
    <ContainerBox>
        <VideoContainer src={videofile} autoPlay loop muted playsInline />
        <TextContainer>
            <TextData>{text1}</TextData> 
            <TextData>{text2}</TextData> 
            <TextData>{text3}</TextData> 
            <TextSmallData>{text4}</TextSmallData>
            <Link to="/contact">
                <ButtonData>
                    <ButtonText>Connect with us</ButtonText>
                </ButtonData>
            </Link>
        </TextContainer>
    </ContainerBox>
  )
}

export default Coverpage