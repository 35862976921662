import React, { useEffect } from 'react'
import Navbar from '../comp/Navbar'
import Coverpage from './pageex/homepage/Coverpage'
import Footer from '../comp/Footer'
import bgvideo from '../media/bg1.mp4'
import Servicedash from './pageex/servicepage/Servicedash'
import Servicetext from './pageex/servicepage/Servicetext'
import techcompanyamico from '../media/ill/techcompanyamico.png'
import Serverbro from '../media/ill/Serverbro.png'
import Homegridbox from '../comp/Homegridbox';
import startup from '../media/ill/startup.png'
import sap from '../media/ill/sap.png'
import eng from '../media/ill/eng.png'
import design from '../media/ill/design.png'
import Consulting from '../media/ill/Consulting.png'

const Service = () => {
  useEffect(() => {
      window.scroll(0,0);
  },[]);
  return (
    <>
      <Navbar nochanges={"#EDEDED"} countfile={0}/>
      <Coverpage text1={"We Deliver "} text2={"Expert "} text3={"Techno Resource Services"} videofile={bgvideo}/>
      <Servicetext />
      <Servicedash imagefile={sap} title={"SAP Resource Services"} desc={"RSB Techno Services provides SAP Expert Resource solution with a wide pool of SAP staffing solution in terms of Procurement Engineer/Process by qualified team. RSB delivers matchless user satisfaction and high productivity with its core values of customer centricity, result oriented approach and timeliness. RSB Techno Services is an advanced technological SAP consultant and support provider engaged in varied industry verticals comprising of pharmaceutical, telecommunications, manufacturing, automobile, legal services and other professional services for India’s high impact industrial sectors. We provide SAP resources in MM, FICO,ABAP,HR,PP,Quality Management, Sales force admin and  Development. "}/>
      <Servicedash imagefile={techcompanyamico} title={"IT Technical Support Services"} desc={"Streamline business solutions with onsite IT support and managed IT services from RSB Techno Services. We managed the IT services scale to meet the needs of your growing business. Our end-to-end IT management services include expert-level Strategic Consulting, on-site or remote IT Management services, Cloud-based tools and technologies, and Information Security solutions. We have extensive experience in the area of Web Application, Development, Android Application , Mobile App Development, Enterprise Software Development & Data Science, Standard Managed Services, Professional Managed Services, Enterprise Managed Services . We believe in designing solutions that cater to client needs with the right choice of technology and framework without over complicating solutions. We don't want to be just another Outsourcing company but a company which put its thoughts honestly and firmly and leave no stone unturned to come up with the right solution."}/>
      <Servicedash imagefile={design} title="Mechanical Engineering Onsite CAD/Design/CAE services" desc={"RSB Techno Services offers on-site comprehensive solutions (CAD/CAE outsourcing) to Mechanical Engineers, Production Engineers, Design Engineers, and other professionals who rely on 2D and 3D models. With our commitment to being a one-stop solution, we continuously expand our services to meet the diverse needs of our clients. We give you access to a highly flexible resource that integrates with your business and puts you in full control of the workflow. We anchor our exceptional services with an overall goal for our clients achieving world-class competitive advantage. We take keen interest in understanding our clients present and future needs and provide with innovative solutions in a very short time."}/>
      <Servicedash imagefile={Consulting} title="IT Staffing & Consulting" desc={"Our staffing and consulting solutions are focused on creating durable, high-value relationships to help our clients to improve the ROI and achieve the business goals.  We deploy highly trained, certified IT specialists backed by our experienced team of technology professionals. If you need the IT talent to complement your existing team or unfilled needs in your business environment, we can help bridge the gap instantly. We offer staffing services for IT,SAP,Banking & E-commerce sectors,Enginering,Design,Quality. We have a strong recruitment team with an engineering background that works hard to find candidates who meet the needs of our clients.  "}/>
      <Servicedash imagefile={Serverbro} title="Network & Data Security Services" desc={"RSB Techno Services Managed infrastructure and network security services-Firewall Management- Support for multiple firewall technologies, Device management, Device monitoring, Cloud Security, Ransomware,Application Security Services, Data Security, Manage Email security Services. Threat detection and response."}/>
      <Servicedash imagefile={startup} title="Back office Services" desc={"RSB Techno Services  provides a multitude of back office support services to support businesses across different industries. Our back office support reduces the burden off the shoulders of companies so that they can focus on their core business activities. They get access to an efficiently managed staff that can deliver their operational support processes by combining the best elements of professional expertise, technology, data, and domain knowledge. Partnering with us minimizes operational costs and raises organizations’ overall turnover."}/>
      <Footer /> 
    </>
  )
}

export default Service