import React, { useEffect } from 'react'
import Navbar from '../comp/Navbar'
import Footer from '../comp/Footer'
import Careerdash from './pageex/career/Careerdash'
import Careercontent from './pageex/career/Careercontent'
import team from '../media/team.png'
import job from '../media/job.png'

const Career = () => {
  useEffect(() => {
      window.scroll(0,0);
  },[]);
  return (
    <>
      <Navbar nochanges={"#fff"} countfile={1}/>
      <Careerdash />
      <Careercontent title={"Join our team"} imagefile={team} desc={"At RSB Techno Services, we are always looking for talented, passionate, and driven individuals to join our team. We are committed to fostering a diverse, inclusive, and collaborative work environment where everyone has the opportunity to grow and succeed."}/>
      <Careercontent title={"Career Opportunities"} imagefile={job} desc={"We offer a wide range of career opportunities across various departments, including sales, marketing, engineering, installation, and customer service. Whether you’re an experienced professional or just starting your career, we have a variety of roles that can help you grow and develop your skills."}/>
      <Footer /> 
    </>
  )
}

export default Career