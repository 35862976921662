import React from 'react'
import { styled } from 'styled-components';

const Servicetext = () => {
    const ContainerBox = styled.div`
        position: relative;
        width: 100vw;
        padding: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start ;
        flex-direction: column;
        h1 {
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 800;
            color: #757575;
            
        }
        p {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #687076;
            margin-top: 2px;
            /* text-align: center; */
            font-size: 14px;
        line-height: 22px;
        }
        @media screen and (max-width: 1500px) {
            
            padding: 80px;
        }
        @media screen and (max-width: 600px) {
            padding: 20px;
        }
    `;
  return (
    <ContainerBox>
        <h1>Services</h1>
        <p>Our onsite services will provide you with right-sized, competitively priced resources to help you deliver the robust and reliable technology services that your team demands and your business nedd.     
        RSB Techno Services has enriched and strengthened the business verticals of our esteem Client in diversified domains. We believe that the length of our service is proof of the positive effect of our activities on our client's business.</p>
    </ContainerBox>
  )
}

export default Servicetext