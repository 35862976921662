import React from 'react'
import { styled } from 'styled-components';
import bgimg from '../../../media/bg1.jpg'
import techcompanyamico from '../../../media/ill/techcompanyamico.png'

const Servicedash = ({title, desc, imagefile}) => {
    const ContainerBox = styled.div`
        position: relative;
        width: 100vw;
        /* height: 600px; */
        padding: 100px;
        display: grid;
        @media screen and (max-width: 1500px) {            
            padding: 80px;
        }
        @media screen and (max-width: 1200px) {            
            padding: 60px;
        }
        @media screen and (max-width: 600px) {
            padding: 20px;
        }
    `;
    const DarkBox = styled.div`
        position: relative;
        background: #000;
        padding: 50px;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 3fr auto;
        gap: 20px;
        background: rgb(20,79,237);
        background: linear-gradient(157deg, rgba(11,96,176,1) 59%, rgba(110,170,227,1) 100%);
        border-radius: 15px;
        -webkit-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        -moz-box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        box-shadow: 5px 5px 24px -8px rgba(0,0,0,0.40);
        @media screen and (max-width: 1200px) {
            grid-template-columns: 1fr;
        }
        @media screen and (max-width: 760px) {
            padding: 30px;
        }

    `;
    const DarkTextBox = styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    `;
    const DarkImgBox = styled.div`
        position: relative;
        width: 500px;
        height: 500px;
        display: grid;
        justify-self: center;
        align-self: center;
        @media screen and (max-width: 760px) {
            width: 300px;
            height: 300px;
        }
        @media screen and (max-width: 390px) {
            width: 200px;
            height: 200px;
        }
    `;

    const GridTextData = styled.h3`
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 19px;
    `;
    const ImgBox = styled.img`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `;
    const TextSmallbox = styled.p`
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: #ededed;
        font-size: 14px;
        line-height: 22px;
    `;
    
  return (
    <ContainerBox>
        <DarkBox>
            <DarkTextBox>
                <GridTextData>{title}</GridTextData>
                <TextSmallbox>{desc}</TextSmallbox>
            </DarkTextBox>
            <DarkImgBox>
                <ImgBox src={imagefile} />
            </DarkImgBox>
        </DarkBox>
    </ContainerBox>
  )
}

export default Servicedash