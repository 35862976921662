import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import logo from  '../media/logo4.png'

const Navbar = ({nochanges, countfile}) => {
    const Container = styled.div`
        position: fixed;
        width: 100vw;
        height: 80px;
        top: 0;
        left: 0;
        padding: 0px 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: ${({colordisplay}) => (colordisplay == 1 ? nochanges : 'transparent')};
        z-index: 111;
        @media screen and (max-width: 1024px) {
            height: 80px;
            padding: 0 5% 0 5%;
        }
        @media screen and (max-width: 648px) {
            height: 60px;
            padding: 0 5% 0 5%;
        }
        /* border: 1px solid red; */

    `;
    const Logobox = styled.div`
    width: 165px;
    height: 75px;
        position: relative;
        /* background: #000; */
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        a {
            text-decoration:none;
            /* color: #000; */
        }
        @media screen and (max-width: 648px) {
        width: 90px;
        height: 40px;
        }

    `;
    const Logo = styled.img`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit:contain;
    `;
    const Address = styled.div`
        @media screen and (max-width: 1024px) { 
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height: ${({isOpen}) => (isOpen ? '400px' : '0')};
            background: #000;
            z-index: 15;
            /* margin-top: 70px; */
            overflow: hidden;
            transition: height .4s ease;
            
        }
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            @media screen and (max-width: 1024px) { 
                padding: 50px;
                display: grid;
                grid-template-columns: 1fr;
                gap: 5px;
            }

            li {
                a {
                    text-decoration:none;
                    color: #fff;
                    @media screen and (min-width: 1024px) {
                        color: ${({colordisplay}) => (colordisplay == 1 ? '#252525' : '#fff')};
                    }
                }
                padding: 15px 10px;
                margin-left: 19px;
                text-transform: capitalize;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: bold;
                color: #fff;
                @media screen and (min-width: 1024px) {
                    color: ${({colordisplay}) => (colordisplay == 1 ? '#252525' : '#fff')};
                }
                @media screen and (max-width: 1024px) { 
                    text-align: center;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-family: 'Manrope', sans-serif;
                    font-weight: 700;                    
                }
                letter-spacing: 1px;
            }
            .contact {
                background: #0B60B0;
            }
            @media screen and (min-width: 1024px) {
                .contact {
                    display: none;
                }
            }
        }
    `;
    const Navdot = styled.div`
        @media screen and (min-width: 1024px) { 
            display: none;
        }
        width: 35px;
        height: 28px;
        /* border: 1px solid red; */
        display: grid;
        justify-self: end;
        align-self: center;
        gap: 8px;
        position: relative;
        z-index:12332222;

        span {
    position: relative;
            width: 100%;
            /* height: 5px; */
            background: ${({colordisplay}) => (colordisplay == 1 ? '#252525' : '#fff')};
        }
        @media screen and (max-width: 648px) {
            width: 28px;
            height: 20px;
        }
    `;

    const ButtonData = styled.div`
        width: 140px;
        height: 40px;
        background-color: transparent;
        /* margin-top: 25px; */
        border-radius: 30px;
        display: grid;
        place-content: center;
        border: 1px solid #545454;
        border-color: ${({colordisplay}) => (colordisplay == 1 ? '#545454' : '#fff')};
        cursor: pointer;
        transition: ease-in-out .6s;
        a{            
            text-decoration:none;
        }
        &:hover {
            background-color: #0B60B0;
            border: 1px solid #fff;
            .btntext {
                color: #fff;
                transition: ease-in-out .6s;
            }
            transition: ease-in-out .6s;
        }
        @media screen and (max-width: 1024px) { 
            display: none;
        }
    `;
    const ButtonText = styled.h4`
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 800;
        color: ${({colordisplay}) => (colordisplay == 1 ? '#252525' : '#fff')};
        font-size: 14px;
        transition: ease-in-out .6s;
        a{
            
        text-decoration: none;
        }
    `;

    
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  
  const [navbar, setNavbar] = useState(countfile);

  const changeBackground = () => {
      if(window.scrollY >= 150) {
          setNavbar(1);
      }else {
          setNavbar(countfile);
      }
  }
  

  window.addEventListener('scroll', changeBackground); 
  return (
    <Container colordisplay = {navbar}>
        <Logobox>
            <Link to='/'> <Logo src={logo} /></Link>
        </Logobox>
        <Address isOpen={isOpen} colordisplay = {navbar}>
            <ul>
                <li>
                    <Link to="/">home</Link>
                </li>
                <li>
                    <Link to="/about">about us</Link>
                </li>
                <li>
                    <Link to="/service">Services</Link>
                </li>
                <li>
                    <Link to="/career">career</Link>
                </li>
                <li className='contact'>
                    <Link to="/contact">contact us</Link>
                </li>
            </ul>
        </Address>
        <Link to="/contact">
            <ButtonData colordisplay = {navbar}>
                <ButtonText className='btntext' colordisplay = {navbar}>Contact Us</ButtonText>
            </ButtonData>
        </Link>
        <Navdot colordisplay = {navbar} onClick={toggle}>
            <span></span>
            <span></span>
            <span></span>
        </Navdot>
    </Container>
  )
}

export default Navbar